<template>
  <div class="wrapper">
    <div id="column" class="world">
      <ul class="tab flex">
        <li
          v-for="(item, index) in tab"
          :key="index"
          :class="{ active: active == index }"
          @click="tabs(index)"
        >
          {{ item }}
        </li>
      </ul>
      <!-- 能力测评预约 -->
      <section v-show="active == 0">
        <div class="project">
          <el-table
            :data="evaluateList"
            stripe
            style="width: 100%"
            class="table"
            :header-cell-style="{ 'text-align': 'center' }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              prop="name"
              label="机构名称"
              width="240"
            ></el-table-column>
            <el-table-column
              prop="linkPhone"
              label="联系方式"
              width="140"
            ></el-table-column>
            <el-table-column
              prop="apllyTime"
              label="期望日期"
              width="140"
            ></el-table-column>
            <el-table-column prop="address" label="详细地址"></el-table-column>
            <el-table-column
              prop="createTime"
              label="申请时间"
              width="150"
            ></el-table-column>
            <!-- <el-table-column prop="createTime" label="操作" width="110">
              <template slot-scope="scope">
                <p
                  @click="goRouter('/hunan/create/projectDetail', scope.row.id)"
                  class="cursorP"
                >
                  详情
                </p>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
      </section>
      <!-- 心理咨询预约 -->
      <section v-show="active == 1">
        <div class="project">
          <el-table
            :data="evaluateList"
            stripe
            style="width: 100%"
            class="table"
            :header-cell-style="{ 'text-align': 'center' }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              prop="name"
              label="机构名称"
              width="240"
            ></el-table-column>
            <el-table-column
              prop="linkPhone"
              label="联系方式"
              width="140"
            ></el-table-column>
            <el-table-column
              prop="apllyTime"
              label="期望日期"
              width="140"
            ></el-table-column>
            <el-table-column prop="address" label="详细地址"></el-table-column>
            <el-table-column
              prop="createTime"
              label="申请时间"
              width="150"
            ></el-table-column>
            <!-- <el-table-column prop="createTime" label="操作" width="110">
              <template slot-scope="scope">
                <p
                  @click="goRouter('/hunan/create/projectDetail', scope.row.id)"
                  class="cursorP"
                >
                  详情
                </p>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
      </section>

      <div class="pages">
        <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="params.pageNum"
          :page-size="params.pageSize"
          layout="prev, pager, next, total"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "column",
  data() {
    return {
      active: 0,
      tab: ["能力测评预约", "心理咨询预约"],
      total: 0,
      evaluateList: [],
      params: {
        pageNum: 1,
        pageSize: 4,
        type:2
      },
      // today:"",
      // days:"",
      isShow: false,
    };
  },
  filters: {},
  methods: {
    tabs(i) {
      this.active = i;
      // console.log(this.active);
      if (this.active == "0") {
        this.params.type = 2;
        this.evaluate();
      }
      if (this.active == "1") {
        this.params.type = 1;
        this.evaluate();
      }
    },
    // 能力测评预约
    async evaluate() {
      // this.loading = true;
      try {
        let res = await this.$axios.get(
        "/api/hunanYuyueXinliAplly/myYuyueXinliAplly",
        this.params
      );
        if (res.data.success) {
          this.evaluateList = res.data.data.list;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.evaluateList);
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    goRouter(url, id) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    getTime(time) {
      let date = Date.parse(new Date(time));
      let today = Date.parse(new Date());
      let day = date - today;
      let days = Math.floor(day / (24 * 3600 * 1000));
      return days;
    },
    //分页
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.tabs(this.active);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.tabs(this.active);
    },
  },
  created() {
    this.tabs(this.active);
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/public";
.world {
  padding: 30px 0;
  margin: 0 20px;
  .tab {
    padding-bottom: 10px;
    border-bottom: 1px solid @e1;

    li {
      position: relative;
      width: 140px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }

    li:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 20px;
      background: @e1;
    }

    li:last-child:before {
      display: none;
    }

    li.active {
      color: @00;
    }

    li.active::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -10px;
      transform: translateX(-50%);
      width: 115px;
      height: 2px;
      background: @00;
    }
  }
  .flex {
    display: flex;
  }
  .project {
    .table {
      padding-top: 30px;
    }
  }
  .activity {
    ul {
      margin-top: 24px;
      li {
        margin-bottom: 20px;
        img {
          width: 176px;
          height: 120px;
          border-radius: 6px;
          margin-right: 17px;
        }
        .text {
          position: relative;
          h3 {
            font-size: 18px;
            font-weight: 600;
            margin: 8px 0 13px 0;
            width: 740px;
            position: relative;
            span {
              font-size: 16px;
              font-family: PingFang SC;
              color: #666666;
              line-height: 14px;
              position: absolute;
              right: 0;
              top: 3px;
            }
            .two {
              color: #28a46d;
            }
            .three {
              color: #e62220;
            }
          }
          p {
            color: #666666;
            font-size: 14px;
            line-height: 14px;
          }
          .day {
            margin-bottom: 34px;
            color: #e62220;
          }
          .time {
            position: absolute;
            top: 92px;
          }
        }
      }
    }
  }
}

.wrapper {
  background: #ffffff;
}
/deep/ .el-loading-spinner {
  top: 30%;
}
.more {
  color: #00924c;
  text-decoration: underline;
  font-size: 14px;
}
/deep/ .el-loading-mask {
  top: 28px;
  right: 70px;
}
/deep/ .el-loading-spinner {
  top: 5%;
}
.pages {
  padding-top: 90px;
}
</style>
